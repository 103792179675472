<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import _debounce from 'lodash.debounce';
import EmptyList from "@/components/widgets/empty_list"
import {predefinedInteractions} from "../../../services/interactions";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";

import {
	interactionMethods
} from "@/state/helpers";

/**
 * Dashboard Component
 */
export default {
  page: {
	title: "Interactions",
	meta: [
		{
			name: "description",
			content: appConfig.description,
		},
	],
  },
  components: {
    Layout,
    PageHeader,
    EmptyList,
    Multiselect
  },
  data() {
	return {
		title: "Custom Interactions",
		interactions: [],
    predefinedInteractions: predefinedInteractions,
    currentPage:1,
		perPage:8,
		rows:0,
    isLoading:false,
    pageLoaded:false,
    empty_config:{
      "title" : this.$t('interactions.empty_title'),
        "subtitle" : this.$t('interactions.empty_subtitle'),
        "buttonText" : 'Nueva interacción',
        icon: "fa-cubes"
      },
      empty_list_config:{
          "title" : this.$t('interactions.empty_search_title'),
          "linkButtonText" : this.$t("common.reset_search"),
          icon: "fa-cubes"
      },
      query:'',
      showNewInteraction: false,
      interaction:{
        parameters:[]
      },
      submitted:false,
      submittedParameter:false,
      parameter: {
        type:null,
        id:null
      },
      parameter_types: [
          {
              id: 'item',
              name: "Item"
          },
          {
              id: 'text',
              name: this.$t("properties.property_types_text")
          },
          {
              id: 'number',
              name: this.$t("properties.property_types_number")
          },
          {
              id: 'date',
              name: this.$t("properties.property_types_date")
          },
          {
              id: 'boolean',
              name: this.$t("properties.property_types_boolean")
          },
      ],
      auxParameter: null,
      confirmDelete: false,
      isInteractionKeyNotDuplicated: true
    }
  },
  validations: {
      interaction: {
          name: { required },
          key: { required },
      },
      parameter:{
        id: { required },
        type: { required },
      }
  },
  mounted() {
    this.loadCustomInteractions(true);
  },
  computed : {
    isParameterIdValid(){
      return this.validateParameterId(this.parameter.id)
    },
    isParameterIdNotDuplicated(){
      return this.interaction.parameters.filter(p=>p.id == this.parameter.id).length == 0
    },
    isInteractionKeyValid(){
      return this.validateParameterId(this.interaction.key)
    },
  },
  created(){
		this.debounceCustomInteractions = _debounce(this.searchCustomInteractions.bind(this), 1000);
	},
  methods: {
	...interactionMethods,

  onNewCustomInteraction(){
    this.interaction = {
      name: '',
      key:'',
      parameters:[]
    }
    this.submitted = false;
    this.showNewInteraction = true;
  },
  
  loadCustomInteractions(){

    let loader = this.$loading.show();
    const params={
      q: `where[project]=${localStorage.getItem('current_project')}&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`,
    }
    this.isLoading = true;
    this.getInteractions(params).then((res)=>{
        this.interactions = this.predefinedInteractions.concat(res.data);
        this.rows= res.totalCount;
        this.pageLoaded = true;
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t('interactions.get_interactions_error'),title: this.$t('interactions.title') });		
      }).finally(()=>{
        this.isLoading = false
        loader.hide();
      })
  },

  onCustomInteractionsListPageClicked(){
    this.loadCustomInteractions();
  },
  onEditCustomInteractionClicked(data){
    this.interaction = Object.assign({}, data);
    this.submitted = false;
    this.submittedParameter = false;
    this.parameter= {
        type:null,
        id:null
      },
    this.showNewInteraction = true;
  },

  searchCustomInteractions(query){
			
			let loader = this.$loading.show();
			this.isLoading = true;
      this.query = query;
      this.empty_list_config.subtitle =  this.$t('interactions.empty_search_text').replaceAll('#', this.query);

			if(query){
				this.currentPage = 1;
					const params={
							q: `where[project]=${localStorage.getItem('current_project')}&where[name][$regex]=.*${query}.*&where[name][$options]=i&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`,
						}
						this.getInteractions(params).then((res)=>{
							this.interactions = res.data;
              this.rows= res.totalCount;
            }).catch(()=>{
							this.$notify({ type: 'error', text: this.$t('interactions.get_interactions_error'),title: this.$t('interactions.title') });
						}).finally(()=>{
							this.isLoading = false;
							loader.hide();
						});
            
			}else{
				this.isLoading = false;
				loader.hide();
				this.currentPage = 1;
				this.loadCustomInteractions();
			}
		},

    onResetSearchClicked(){
      this.query = '';
      this.loadCustomInteractions();
    },
    onAddParameterClicked(){
      this.submittedParameter = true;

      if (this.$v.parameter.$invalid) {
        return;
      }
      
      if(!this.validateParameterId(this.parameter.id))
        return;

      if(!this.isParameterIdNotDuplicated)
        return;

      this.interaction.parameters.push(this.parameter);
      this.parameter = {
        type:null,
        id: null
      };
      this.submittedParameter = false;
      this.auxParameter = null

    },
    onParameterTypeSelected(){
      this.parameter.type = this.auxParameter.id;
    },
    onRevenueChecked(par){
      
      this.interaction.parameters.forEach((p)=>{
        if(p.id!=par.id){
          p.revenue = false;
        }
      })
      if(par.revenue){
        this.interaction.revenue = par.id;
      }else{
        this.interaction.revenue = null
      }
    },
    validateParameterId(id){
      let isOk = true;
      isOk = !/^_/.test(id);
      isOk = !/[A-Z]/.test(id) && isOk;
      isOk = !/[!@#$%^&*(),.'?":{}|<>]/.test(id) && isOk
      isOk = !/\s/.test(id) && isOk;
      
      return isOk
    },
    onConfirmNewInteractionClicked(){
      this.submitted = true;

      this.$v.$touch();

      if (this.$v.interaction.$invalid) {
				return;
			}

      if(!this.validateParameterId(this.interaction.key))
        return;

      this.isInteractionKeyNotDuplicated = this.predefinedInteractions.filter(p=>p.key==this.interaction.key).length == 0
      if(!this.isInteractionKeyNotDuplicated)
        return;

      const params={
        q: `where[project]=${localStorage.getItem('current_project')}&where[key]=${this.interaction.key}`,
      }
      const loader = this.$loading.show()
      this.getInteractions(params).then((res)=>{
        if(res.data.length == 0 || (res.data.length==1 && res.data[0]._id == this.interaction._id)){
          this.createOrUpdateCustomInteraction()
        }else{
          this.isInteractionKeyNotDuplicated = false
        }
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t('interactions.create_interaction_error'),title:  this.$t('interactions.title') });
      }).finally(()=>{
        loader.hide();
      });
    },
    onRemoveCustomInteraction(data){
      this.interaction = data;
      this.confirmDelete = true;
    },
    onConfirmRemoveInteractionClicked(){
      let loader = this.$loading.show();

      this.deleteInteraction(this.interaction._id).then(()=>{
        this.currentPage=1;
        this.loadCustomInteractions();
        this.$notify({ type: 'success', text: this.$t('interactions.delete_interaction_success'),title:  this.$t('interactions.title') });
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t('interactions.delete_interaction_error'),title:  this.$t('interactions.title') });
      }).finally(()=>{
        loader.hide();
      })
    },
    createOrUpdateCustomInteraction(){
      const loader = this.$loading.show();
      this.interaction.project = localStorage.getItem('current_project');

      if(!this.interaction._id){
        this.createInteraction(this.interaction).then(()=>{
          this.$notify({ type: 'success', text: this.$t('interactions.create_interaction_success'),title: this.$t('interactions.title') });
          this.showNewInteraction = false;
          this.isInteractionKeyNotDuplicated = true
          this.currentPage = 1;
          this.loadCustomInteractions();
        }).catch((ex)=>{
          // eslint-disable-next-line no-console
          console.log(ex);
          this.$notify({ type: 'error', text: this.$t('interactions.create_interaction_error'),title: this.$t('interactions.title') });
        }).finally(()=>{
          loader.hide()
        })
      }else{
        this.updateInteraction(this.interaction).then(()=>{
          this.$notify({ type: 'success', text: this.$t('interactions.update_interaction_success'),title: this.$t('interactions.title') });
          this.showNewInteraction = false;
          this.isInteractionKeyNotDuplicated = true
          this.currentPage = 1;
          this.loadCustomInteractions();
        }).catch(()=>{
          this.$notify({ type: 'error', text: this.$t('interactions.update_interaction_error'),title: this.$t('interactions.title') });
        }).finally(()=>{
          loader.hide()
        })
      }
    },
    onRemoveParameter(par){
      this.interaction.parameters = this.interaction.parameters.filter(p=>p.id!=par.id)
    }
  },
  
};
</script>

<template>
	<Layout>
		<PageHeader :title="$t('interactions.title')" :items="[]" />
		<div class="row">
      <div class="col-sm-12">
        <div class="card mb-3">  
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-4">
                        <div class="search-box me-2 mb-0 d-inline-block">
                        <div class="position-relative">
                            <input
                            type="text"
                            class="form-control"
                            :placeholder="$t('common.search')"
                            @input="debounceCustomInteractions($event.target.value)"
                            v-model="query"
                            />
                            <i class="bx bx-search-alt search-icon"></i>
                        </div>
                        </div>
                    </div>
                    <div class="col-sm-8">
                        <div class="text-sm-end">
                          <button
                            type="button"
                            class="btn btn-primary mb-0 me-0" @click="onNewCustomInteraction"
                            v-if="interactions.length>0 || query!=''">
                              <i class="mdi mdi-plus me-1"></i> {{ $t('interactions.new') }}
                          </button>
                        </div>
                    </div>
                </div>
                <EmptyList :config="empty_list_config" v-if="!isLoading && interactions.length == 0 && query!=''" @onButtonClicked="onResetSearchClicked" class="mt-3 mb-3"/>
                <EmptyList :config="empty_config" v-if=" !isLoading && interactions.length == 0 && query == ''" @onButtonClicked="onNewCustomInteraction" class="mt-3"/>
            </div>
            <div class="table-responsive mb-0" v-if="pageLoaded && interactions.length > 0">
              <table  id="my-table" class="table align-middle table-nowrap table-hover mb-0">
                <thead class="table-light">
                  <tr>
                    <th scope="col">{{$t('interactions.name')}}</th>
                    <th scope="col">{{$t('interactions.key')}}</th>
                    <th scope="col">{{$t('interactions.type')}}</th>
                    <th scope="col" class="text-center">{{$t('interactions.parameters')}}</th>
                    <th scope="col" class="text-end"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="interaction in interactions" :key="interaction.key">
                    <td>
                      <h5 class="font-size-14 mb-1" v-on:click="onEditCustomInteractionClicked(interaction)">
                        <a href="#" class="text-dark">{{interaction.name}}</a>
                      </h5>
                    </td>
                    <td>
                      {{interaction.key}}
                    </td>
                    <td>
                      {{interaction.default ? 'Default' : 'Custom' }}
                    </td>
                    <td class="text-center">
                      {{interaction.parameters?.length || 0}}
                    </td>
                  <td class="text-end">
                    <i class="fas fa-edit text-success me-2 interact" v-on:click="onEditCustomInteractionClicked(interaction)" v-if="!interaction.default"></i>
                    <i class="fas fa-trash-alt text-danger me-1 interact" v-on:click="onRemoveCustomInteraction(interaction)" v-if="!interaction.default"></i>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
        </div>
        <div class="row mb-3" v-if="rows > perPage">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination
                      @input="onCustomInteractionsListPageClicked"
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage">
                    </b-pagination>
                  </ul>
                </div>
              </div>
            </div>
      </div>
      <b-modal v-model="showNewInteraction" id="modal-center" :title="interaction._id ? interaction.name : $t('interactions.new')" title-class="font-18" @ok.prevent="onConfirmNewInteractionClicked"  :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
        <div class="row">
            <div class="mb-3">
                <label for="title">{{ $t('interactions.name')}}*</label>
                <input
                    id="title"
                    v-model="interaction.name"
                    type="text"
                    class="form-control"
                    :placeholder="$t('interactions.name_placeholder')"
                    :class="{ 'is-invalid': submitted && $v.interaction.name.$error }"
                />
                <div v-if="submitted && !$v.interaction.name.required" class="invalid-feedback">
                    {{$t('interactions.name_required')}}
                </div>
            </div>
            <div class="mb-3">
                <label for="title">{{$t('interactions.key')}}*</label>
                <input
                    id="title"
                    v-model="interaction.key"
                    type="text"
                    class="form-control"
                    :placeholder="$t('interactions.key_placeholder')"
                    :class="{ 'is-invalid': submitted && $v.interaction.key.$error }"
                />
                <div v-if="submitted && !$v.interaction.key.required" class="invalid-feedback">
                    {{$t('interactions.key_required')}}
                </div>
                <div v-else-if="submitted && !isInteractionKeyValid" class="invalid-feedback" style="display: block;">
                    {{$t('interactions.key_error')}}
                  </div>
                  <div v-else-if="submitted && !isInteractionKeyNotDuplicated" class="invalid-feedback" style="display: block;">
                    {{$t('interactions.key_duplicate_error')}}
                  </div>
            </div>
            <div class="mb-3">
              <label for="title">{{$t('interactions.parameters')}}</label>
              <div class="row align-items-baseline">
                <div class="col-5 pe-0">
                  <b-form-input v-model="parameter.id" :placeholder="$t('interactions.key_placeholder')" :class="{ 'is-invalid': submittedParameter && $v.parameter.id.$invalid }" />
                  <div v-if="submittedParameter && !$v.parameter.id.required" class="invalid-feedback">
                    {{$t('interactions.key_required')}}
                  </div>
                  <div v-else-if="submittedParameter && !isParameterIdValid" class="invalid-feedback" style="display: block;">
                    {{$t('interactions.key_error')}}
                  </div>
                  <div v-else-if="submittedParameter && !isParameterIdNotDuplicated" class="invalid-feedback" style="display: block;">
                    {{$t('interactions.key_duplicate_error')}}
                  </div>
                </div>
                <div class="col-5 pe-0"> 
                  <multiselect 
                      v-model="auxParameter"
                      :options="parameter_types"
                      :selectLabel="$t('common.select')"
                      :deselectLabel="$t('common.deselect')"
                      :placeholder="$t('interactions.type_placeholder')"
                      :multiple="false"
                      :class="{'is-invalid': submittedParameter && !$v.parameter.type.required}"
                      track-by="id" 
                      label="name"
                      :show-labels="false"
                      :showNoResults="false"
                      @input="onParameterTypeSelected">
                  </multiselect>
                  <div v-if="submittedParameter && !$v.parameter.type.required" class="invalid-feedback">
                    {{$t('interactions.type_required')}}
                  </div>
                </div>
                <div class="col-2">
                  <button class="btn btn-sm btn-primary" @click="onAddParameterClicked">{{$t('common.add')}}</button>
                </div>
              </div>
              <div class="row mt-3" v-if="interaction.parameters.length > 0">
                <div class="col">
                  <table class="table table-sm align-middle table-nowrap table-hover">
                    <thead class="table-light">
                      <tr>
                        <th scope="col" class="align-middle">{{$t('interactions.key')}}</th>
                        <th scope="col" class="align-middle">{{$t('interactions.type')}}</th>
                        <th class="text-end"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="par in interaction.parameters" :key="par.id">
                        <td class="align-middle">{{par.id}}</td>
                        <td class="align-middle">{{par.type}}</td>
                        <td class="text-end"><i class="fas fa-trash-alt text-danger me-1 interact" v-on:click="onRemoveParameter(par)"></i></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
      </b-modal>
      <b-modal v-model="confirmDelete" id="modal-center" centered :title="$t('common.confirm')" title-class="font-18" @ok="onConfirmRemoveInteractionClicked"  :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
          <p>{{$t('interactions.remove_interaction')}}</p>
      </b-modal>
    </div>
  </Layout>
</template>

<style scoped>
.interact{
	cursor: pointer;
  }

.bold{
	font-weight: bold;
}
</style>